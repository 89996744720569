import styled from "styled-components"

import { SectionContent as SectionContentComponent } from "../"

import { breakpoints, sizes } from "../../../styles/breakpoints"

export const SectionContent = styled(SectionContentComponent)`
  .card {
    &:not(:last-of-type) {
      margin-bottom: 50px;
      @media ${breakpoints.lessThan(sizes.tablet)} {
        margin-bottom: 40px;
      }
    }

    .c-content {
      .img-wrapper {
        border-radius: 4px;
      }

      .paragraph {
        line-height: 1.5;
      }
    }

    &:nth-of-type(even) {
      text-align: right;
      @media ${breakpoints.lessThan(sizes.tablet)} {
        text-align: left;
      }

      .c-content {
        @media ${breakpoints.greaterThan(sizes.tablet)} {
          .img-wrapper {
            order: 2;
            margin-right: 0;
            margin-left: 30px;
          }

          .paragraph {
            order: 1;
          }
        }
      }
    }
  }
`
