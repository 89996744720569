import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import { Container } from "../../../styles/container"
import { SectionHeader, SectionContent, SectionTitle } from "../"
import { Paragraph } from "../../Text"
import SectionHero from "../SectionHero"
import CompanyGuidelines from "../../CompanyGuidelines"
import SectionOperation from "../SectionOperation"

import * as S from "./styled"

const SectionAboutIntern = () => (
  <S.Section>
    <SectionHeader>
      <SectionHero>
        <Container>
          <SectionTitle as="h1" medium noMargin colorInverse>
            Quem somos
          </SectionTitle>
        </Container>
      </SectionHero>
    </SectionHeader>

    <SectionContent className="s-content">
      <Container>
        <div className="box-content">
          <StaticImage
            className="s-image"
            src="../../../images/matriz.webp"
            objectFit="cover"
            objectPosition="left"
            alt="Nossa Matriz"
          />

          <Paragraph darken className="paragraph">
            Nós somos uma empresa com quase duas décadas de atuação no cenário
            jurídico. Contamos com sistema operacional moderno, informatizado,
            departamentos específicos e atendimento personalizado, visando
            sempre excelência no serviço prestado. Com o propósito de garantir o
            amplo acesso à justiça, principalmente dos grupos socialmente
            vulneráveis.
          </Paragraph>
          <Paragraph darken className="paragraph">
            Além de trabalharmos em várias áreas do direito, prestamos vários
            serviços sociais à sociedade, pois entendemos nosso papel como
            essencial na construção de uma sociedade mais justa e igualitária.
            Sendo assim, nos colocamos sempre à disposição da comunidade e de
            seus representantes.
          </Paragraph>
          <Paragraph darken className="paragraph">
            Oferecemos uma ótima estrutura de atendimento, seja através das
            nossas plataformas virtuais, onde atendemos as demandas vindas de
            todo país em tempo real, ou em nossos escritórios físicos,
            estrategicamente localizados, com unidades distribuídas em vários
            Estados.
          </Paragraph>
          <Paragraph darken className="paragraph">
            Temos a concepção de que a Advocacia é via indispensável para
            garantia dos direitos individuais e coletivos. Desta forma, para
            garantir o rápido acesso à justiça, não há cobrança de valores
            antecipados nas ações de caráter alimentar, onde o recebimento de
            honorários fica condicionado ao êxito da demanda.
          </Paragraph>
          <Paragraph darken className="paragraph">
            Conte com nossa experiência na defesa dos seus interesses, seja no
            âmbito judicial, nas mais diversas áreas, bem como na mediação de
            conflitos de forma extrajudicial.
          </Paragraph>
          <Paragraph darken className="paragraph">
            Temos a certeza que a Ruy Molina Advocacia é a melhor solução com o
            melhor custo benefício.
          </Paragraph>
        </div>

        <CompanyGuidelines />

        <SectionOperation />
      </Container>
    </SectionContent>
  </S.Section>
)

export default SectionAboutIntern
