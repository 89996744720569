import styled from "styled-components"

import { breakpoints, sizes } from "../../../styles/breakpoints"

export const Card = styled.article`
  .c-header {
    margin-bottom: 20px;

    .title {
      font-size: 1.6rem;
      font-weight: bold;
      color: var(--color-primary);
      @media ${breakpoints.lessThan(sizes.tablet)} {
        font-size: 1.4rem;
      }
    }
  }

  .c-content {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    position: relative;

    @media ${breakpoints.lessThan(sizes.tablet)} {
      flex-direction: column;
    }

    .img-wrapper {
      flex: 1;
      margin-right: 25px;
      @media ${breakpoints.lessThan(sizes.desktop)} {
        margin-right: 15px;
        margin-bottom: 15px;
      }

      @media ${breakpoints.lessThan(sizes.tablet)} {
        margin-right: 0;
        margin-bottom: 0;
      }

      .img {
        width: 100%;
        height: 100%;
      }
    }

    .paragraph {
      flex: 1;
      margin-top: 10px;
      text-align: justify;
      color: var(--color-primary);

      @media ${breakpoints.lessThan(sizes.desktop)} {
        margin-top: 8px;
      }
      @media ${breakpoints.lessThan(sizes.tablet)} {
        margin-top: 15px;
      }
    }
  }
`
