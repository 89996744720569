import * as React from "react"

import Seo from "../components/seo"
import Layout from "../components/Layout"
import SectionAboutIntern from "../components/Section/SectionAboutIntern"
import WppButtonFloat from "../components/Button/WppButtonFloat"

const AboutPage = () => (
  <Layout>
    <Seo title="Quem somos" />
    <SectionAboutIntern />
    <WppButtonFloat />
  </Layout>
)

export default AboutPage
