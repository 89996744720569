import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import { Section, SectionHeader, SectionTitle } from "../"
import CardOperation from "../../Card/Operation"

import * as S from "./styled"

const SectionOperation = () => (
  <Section id="areas-de-atuacao">
    <SectionHeader centered>
      <SectionTitle as="h4">Áreas de atuação</SectionTitle>
    </SectionHeader>

    <S.SectionContent>
      <CardOperation className="card">
        <header className="c-header">
          <h5 className="title">Direito Previdenciário</h5>
        </header>
        <div className="c-content">
          <StaticImage
            className="img-wrapper"
            imgClassName="img"
            src="../../../images/areas-of-expertise/prev.jpg"
            alt="Image title"
            placeholder="blurred"
            objectFit="cover"
            objectPosition="center"
            // layout="fixed"
            // width={200}
            // height={200}
          />
          <p className="paragraph">
            Atuamos na defesa dos direitos previdenciários e da seguridade
            social, nas esferas administrativa e judicial, de forma a garantir
            celeridade na concessão dos mais diversos benefícios, como pensões,
            aposentadorias, auxílios, salário maternidade, amparo assistencial
            (BPC – LOAS). Oferecemos trabalho diferenciado e especializado em
            revisões de valores de benefícios e contribuições, além de elaborar
            planejamento previdenciário. Desempenhamos atividades no âmbito do
            Regime Geral da Previdência Social e também dos Regimes Próprios e
            Previdências.
          </p>
        </div>
      </CardOperation>

      <CardOperation className="card">
        <header className="c-header">
          <h5 className="title">Direito Civil</h5>
        </header>
        <div className="c-content">
          <StaticImage
            className="img-wrapper"
            imgClassName="img"
            src="../../../images/areas-of-expertise/civil.jpg"
            alt="Image title"
            placeholder="blurred"
            objectFit="cover"
            objectPosition="center"
            // layout="fixed"
            // width={200}
            // height={200}
          />
          <p className="paragraph">
            Oferecemos soluções para as mais diversas demandas das relações
            civis, na defesa dos interesses de posse e propriedade, direito das
            obrigações, contratos em geral, rescisão contratual, execuções
            judiciais e extrajudiciais, alimentos, interdição, dano patrimonial
            e moral, repetição de indébito, indenizações por responsabilidade
            civil. Sempre com foco na melhor e mais rápida resolução do
            conflito.
          </p>
        </div>
      </CardOperation>

      <CardOperation className="card">
        <header className="c-header">
          <h5 className="title">Direito do Trabalho</h5>
        </header>
        <div className="c-content">
          <StaticImage
            className="img-wrapper"
            imgClassName="img"
            src="../../../images/areas-of-expertise/trabalho.jpg"
            alt="Image title"
            placeholder="blurred"
            objectFit="cover"
            objectPosition="center"
            // layout="fixed"
            // width={200}
            // height={200}
          />
          <p className="paragraph">
            Defendemos os interesses dos empregados e empregadores em diversas
            situações, como reconhecimento do vínculo de emprego, horas extras,
            férias, 13º salário, justa causa indevida, garantia de estabilidade,
            danos morais e assédio, acidente de trabalho. Ainda prestamos
            assessoria jurídica preventiva em âmbito consultivo e contencioso,
            abrangendo a elaboração e análise de contratos de trabalho, com foco
            na eliminação ou mitigação dos riscos trabalhistas. Prestamos
            assessoria em todas as instâncias, em demandas que envolvam
            reclamações trabalhistas individuais ou coletivas, em sede de
            processos administrativos ou judiciais. Elaboramos estudos e
            pareceres sobre temas de direito do trabalho e previdência,
            inclusive planos de previdência privada.
          </p>
        </div>
      </CardOperation>

      <CardOperation className="card">
        <header className="c-header">
          <h5 className="title">Direito do Consumidor</h5>
        </header>
        <div className="c-content">
          <StaticImage
            className="img-wrapper"
            imgClassName="img"
            src="../../../images/areas-of-expertise/consu.jpg"
            alt="Image title"
            placeholder="blurred"
            objectFit="cover"
            objectPosition="center"
            // layout="fixed"
            // width={200}
            // height={200}
          />
          <p className="paragraph">
            Respaldado da legislação consumerista, defendemos amplamente os
            interesses dos cidadãos como consumidores, perante o PROCON e em
            todas as esferas do poder Judiciário, em ações de indenização por
            dano moral e material, repetição de indébito, inclusão indevida no
            cadastro de proteção ao crédito (SPC/SERASA), cláusulas abusivas,
            entre outras. Estamos aptos a propor qualquer tipo de ação
            necessária para a defesa dos interesses das pessoas envolvidas em
            uma relação de consumo
          </p>
        </div>
      </CardOperation>

      <CardOperation className="card">
        <header className="c-header">
          <h5 className="title">Direito Empresarial</h5>
        </header>
        <div className="c-content">
          <StaticImage
            className="img-wrapper"
            imgClassName="img"
            src="../../../images/areas-of-expertise/empresarial.jpg"
            alt="Image title"
            placeholder="blurred"
            objectFit="cover"
            objectPosition="center"
            // layout="fixed"
            // width={200}
            // height={200}
          />
          <p className="paragraph">
            Prestamos assessoria jurídica para empresas e empresários, afim de
            orientar a encontrar soluções mais adequadas, para possam ampliar
            sua participação no mercado, prevenir perdas financeiras e melhorar
            a eficiência e lucratividade da atividade empresarial. Além de
            mediar conflitos envolvendo os interesses empresários ou
            relacionados à empresa.
          </p>
        </div>
      </CardOperation>

      <CardOperation className="card">
        <header className="c-header">
          <h5 className="title">Direito Tributário</h5>
        </header>
        <div className="c-content">
          <StaticImage
            className="img-wrapper"
            imgClassName="img"
            src="../../../images/areas-of-expertise/tributario.jpg"
            alt="Image title"
            placeholder="blurred"
            objectFit="cover"
            objectPosition="center"
            // layout="fixed"
            // width={200}
            // height={200}
          />
          <p className="paragraph">
            Contamos com especialistas em tributação, para proporcionar as
            melhores soluções em recuperação de créditos tributários,
            planejamento tributário, consultoria tributária, gestão do passivo
            tributário, Compliance Tributário. Ainda auxiliamos nos pagamentos
            corretos dos tributos, para proporciona melhor rentabilidade com
            menor custo para as empresas.
          </p>
        </div>
      </CardOperation>
    </S.SectionContent>
  </Section>
)

export default SectionOperation
