import styled from "styled-components"
// import { rgba } from "polished"
// import { colors } from "../../styles/jscolors"

import { sizes, breakpoints } from "../../styles/breakpoints"

export const CompanyGuidelines = styled.div`
  margin-top: 60px;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  gap: 30px;

  .card.-guideline {
    position: relative;
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex: 1;
    width: 33%;
    height: 230px;
    padding: 20px 40px;
    background-color: var(--color-white);
    border-radius: 4px;
    overflow: hidden;
    transition: transform ease 0.2s;
    @media ${breakpoints.lessThan(sizes.desktop)} {
      flex: unset;
      width: 100%;
      height: auto;
      padding: 35px;
    }

    /* highlight */
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 10px;
      background-color: var(--color-primary);
      transform: translateX(-5px);
      transition: transform ease-in-out 0.2s;
    }

    .c-header {
      .c-icon {
        width: 40px;
        height: 40px;
      }
    }

    .c-title {
      margin-top: 12px;
      color: var(--color-primary);
      font-weight: bold;
      font-size: 1.5rem;
    }

    .c-paragraph {
      margin-top: 10px;
      line-height: 1.2;
      /* font-size: 1rem; */
      color: var(--color-grayscale5);
    }

    &:hover {
      &::before {
        transform: translateX(0);
      }
    }
  }
`
