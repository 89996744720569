import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import * as S from "./styled"

const CompanyGuidelines = props => (
  <S.CompanyGuidelines className={props.className}>
    <article className="card -guideline">
      <header className="c-header">
        <StaticImage
          className="c-icon"
          src="../../images/icons/auction.svg"
          objectFit="contain"
          objectPosition="center"
          placeholder="tracedSVG"
          alt="Missão"
        />
        <h4 className="c-title">Missão</h4>
      </header>
      <div className="c-content">
        <p className="c-paragraph">
          Garantir amplo acesso do cidadão à justiça, com foco na excelência da
          prestação de serviço, visando o desenvolvimento social e combate às
          desigualdades.
        </p>
      </div>
    </article>

    <article className="card -guideline">
      <header className="c-header">
        <StaticImage
          className="c-icon"
          src="../../images/icons/balance.svg"
          objectFit="contain"
          objectPosition="center"
          placeholder="tracedSVG"
          alt="Visão"
        />
        <h4 className="c-title">Visão</h4>
      </header>
      <div className="c-content">
        <p className="c-paragraph">
          Atuar em todo território nacional, oferecer métodos inovadores de
          acesso à justiça, para nos tornar a melhor solução em serviços
          jurídicos do Brasil.
        </p>
      </div>
    </article>

    <article className="card -guideline">
      <header className="c-header">
        <StaticImage
          className="c-icon"
          src="../../images/icons/accept.svg"
          objectFit="contain"
          objectPosition="center"
          placeholder="tracedSVG"
          alt="Valores"
        />
        <h4 className="c-title">Valores</h4>
      </header>
      <div className="c-content">
        <p className="c-paragraph">
          Prezamos sempre pela ética, profissionalismo e responsabilidade
          social, na busca incessante pela garantia dos direitos fundamentais.
        </p>
      </div>
    </article>
  </S.CompanyGuidelines>
)

export default CompanyGuidelines
